#--description_container p strong{
    font-size: larger;
    font-weight: 400 ;
 }
 
 #--description_container_summary {
     white-space: pre-line;
 }
 
 #--description_container p{
     margin-top: 22px;
     font-weight: 300;
     font-size:small;
 }
