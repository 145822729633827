@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #1A2745;
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
      width: 15px;
      height: 20px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
      background-color: #28303d;
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background-color: #E0A845;
      border-radius: 100vh;
      border: 4px solid #28303d;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: #e0a74588;
    }
  
  }