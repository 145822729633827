.container-slider {
    width: 815px;
    height: 368px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 1100px){
    .container-slider {
     width: 650px;
    }
  }
  @media screen and (max-width: 900px){
    .container-slider {
     width: 550px;
    }
  }
  .slide {
    width: fit-content;
    height: fit-content;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
  }
  .next {
    top: 50%;
    right: 0px;
  }